const cleanHTML = (text: string) => {
  const cleaned = text
    .replace(/\n+/g, "\n") // More than one new line is 1 new line
    .replace(/\t+/g, "\t") // More than one tab is a tab
    .replace(/ {2,}/g, " ") // More than one space is a space
    .replace(/\n +/g, "\n") // A newline followed by a space, should just be a new line
    .replace(/\n/g, ""); // Finally remove all new lines

  return cleaned;
};

export { cleanHTML };
