import axios from "axios";
import { esiStatus } from "../interfaces/esiTypes";
import { ConstellationInfo, SystemInfo } from "../interfaces/eveTypes";

const baseURL = "https://esi.evetech.net/latest/";

// https://httpstat.us/404

export const getEsiStatus = async () => {
  // Note different base URL!

  const url = "https://esi.evetech.net/status.json?version=latest";
  // const url2 = "https://dave1234.com";

  const { data } = await axios.get<esiStatus[]>(url);

  return data;
};

export const checkEndpointStatus = (esiStatuses: esiStatus[]) => {
  if (esiStatuses.length === 0) {
    return false;
  }

  const endPointsUsed = [
    "/route/{origin}/{destination}/",
    "/universe/ids/",
    "/universe/constellations/{constellation_id}/",
    "/universe/systems/{system_id}/",
  ];

  const data: esiStatus[] = [];

  for (const endPointData of esiStatuses) {
    if (endPointsUsed.includes(endPointData.route)) {
      data.push(endPointData);
    }
  }

  for (const endPointData of data) {
    if (endPointData.status !== "green") return false;
  }

  return true;
};

export const getSecureRouteJumps = async (
  originID: number,
  destinationId: number
): Promise<number> => {
  // Jita to Lumegen seems like it's 27 Jumps in this, however it looks 18 in game?
  // Jita 30000142
  // Lumegen 30003432

  try {
    const result = await axios.get(
      baseURL +
        `route/${originID}/${destinationId}/?datasource=tranquility&flag=secure`
    );
    return result.data.length - 1;
  } catch (err) {
    if (err.response.data.error === "No route found")
      return err.response.data.error;
  }
};
export type SystemIDMap = {
  id: number;
  name: string;
};

export const getSystemsIds = async (
  systemNames: string[]
): Promise<SystemIDMap[]> => {
  const result = await axios.post(
    baseURL + `universe/ids/?datasource=tranquility&language=en`,
    systemNames
  );
  return result.data["systems"];
};

export const getSystemInfo = async (systemId: number): Promise<SystemInfo> => {
  // https://esi.evetech.net/latest/universe/systems/30000003
  const result = await axios.get(baseURL + `universe/systems/${systemId}`);
  return result["data"];
};

export const getConstellationInfo = async (
  constellationID: number
): Promise<ConstellationInfo> => {
  // /universe/constellations/{constellation_id}/
  const result = await axios.get(
    baseURL + `universe/constellations/${constellationID}`
  );
  return result["data"];
};

export const getWormholeClass = (constellation: ConstellationInfo) => {
  const hashMap: { [letter: string]: string } = {
    A: "C1",
    B: "C2",
    C: "C3",
    D: "C4",
    E: "C5",
    F: "C6",
    G: "Thera",
    H: "Shattered",
    K: "Drifter",
  };

  const name = constellation.name;
  const grade = hashMap[name[0]];
  const cls = `${grade}`;

  return cls;
};
