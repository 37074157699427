const colors = {
  Grey: "0xff808080",
  Red: "0xffff0000",
  Green: "0xff00ff00",
  Blue: "0xff0000ff",
  Cyan: "0xff00ffff",
  Orange: "0xffffc800",
  DarkGrey: "0xff404040",
  Black: "0xff000000",
  Yellow: "#ffffff00",
};

const wormHolePattern = /J\d\d\d\d\d\d|Thera/;

const fontSizes = {
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  18: 18,
  24: 24,
  30: 30,
  36: 36,
};

const hubs = {
  Jita: 30000142,
  Amarr: 30002187,
  Dodixie: 30002659,
  Hek: 30002053,
  Rens: 30002510,
};

export { colors, fontSizes, hubs, wormHolePattern };
