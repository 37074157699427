enum Grade {
  One = "A+",
  Two = "A",
  Three = "B",
  Four = "C",
}

type Site = {
  [name: string]: Grade;
};

// Wormhole Sites
// NEEDS to have(Faction) with spaces either side.
const dataSites: Site = {
  "Central (Faction) Data Mining Site": Grade.One,
  "Central (Faction) Command Center": Grade.Two,
  "Central (Faction) Survey Site": Grade.Three,
  "Central (Faction) Sparking Transmitter": Grade.Four,
};

const relicSites: Site = {
  "Ruined (Faction) Crystal Quarry": Grade.One,
  "Ruined (Faction) Science Outpost": Grade.Two,
  "Ruined (Faction) Temple Site": Grade.Three,
  "Ruined (Faction) Monument Site": Grade.Four,
};

function createRegExp(siteName: string) {
  return new RegExp(siteName.replace("(Faction)", ".+"));
}

export function returnSiteGrade(siteName: string) {
  const sites = { ...dataSites, ...relicSites };

  for (const key in sites) {
    const reg = createRegExp(key);

    if (reg.test(siteName)) {
      return `(${sites[key]})`;
    }
  }

  return "";
}
