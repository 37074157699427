export const convertToUTC = (dTString: string | number | Date) => {
  const date = new Date(dTString);

  const dateArray = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ] as const;

  const utcDate = new Date(Date.UTC(...dateArray));

  return utcDate.toUTCString();
};
