import {
  Badge,
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";

import { useStickyState } from "../services/localService";

function WormholeDisplay(props: { output: string }) {
  //   let metrics: Metrics = props.metrics;

  let [output, setOutput] = useState(props.output);
  let [displayOutput, setDisplayOutput] = useStickyState(
    false,
    "DisplayOutput"
  );

  useEffect(() => {
    setOutput(props.output);
  }, [props.output]);

  // Remembers stuff in localStorage

  function createMarkup(output: string) {
    output = output.replace('<font size="13">', "<font>");

    return { __html: output };
  }

  return (
    <Fragment>
      <Divider marginY={"1rem"} />
      <Box marginY={"1rem"}>
        <Flex gap={"1rem"}>
          <Checkbox
            isChecked={displayOutput}
            onChange={(e) => setDisplayOutput(!displayOutput)}
          />
          <Text>Display Output</Text>
        </Flex>
      </Box>

      {displayOutput && (
        <Fragment>
          {" "}
          <Divider marginY={"1rem"} />
          <Box marginY={"1rem"}>
            <Flex gap={5}>
              <Heading as={"h6"}>Output Visual</Heading>
              <Badge variant={"solid"} colorScheme={"red"} alignSelf={"center"}>
                Don't copy this!
              </Badge>
            </Flex>
            <Box
              marginTop={"1rem"}
              border={"1px solid"}
              borderRadius={"1rem"}
              padding={"1rem"}
              dangerouslySetInnerHTML={createMarkup(output)}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}

export default WormholeDisplay;
