import { Container } from "@chakra-ui/react";
import { Fragment } from "react";
import CSVInput from "./components/csvInput";

function App() {
  return (
    <Fragment>
      <Container maxW="4xl">
        <CSVInput />
      </Container>
    </Fragment>
  );
}

export default App;
