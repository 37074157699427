import { Circle, Tooltip } from "@chakra-ui/react";
import React from "react";

function StatusCircle(props: { status: string; tooltip: string }) {
  const { status, tooltip } = props;

  const colorHash: { [status: string]: string } = {
    up: "green.600",
    down: "red.600",
    pending: "orange.600",
  };

  let animateCircle = "";

  if (status === "pending") {
    animateCircle = "animateCircle";
  }

  return (
    <Tooltip label={tooltip}>
      <Circle size="20px" bg={colorHash[status]} className={animateCircle} />
    </Tooltip>
  );
}

export default StatusCircle;
