import { useToast } from "@chakra-ui/toast";
import { Fragment, useEffect, useState } from "react";

// Services
import { convertCsvToObject } from "../services/csvService";

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Spacer,
  Textarea,
  ToastPosition,
} from "@chakra-ui/react";
import React from "react";
import { esiStatus } from "../interfaces/esiTypes";
import { generateEasterEggs } from "../services/easterEggService";
import { checkInput } from "../services/errorService";
import { errorTestInput } from "../services/errorTestService";
import { useStickyState } from "../services/localService";
import {
  generateArrayOfSignatures,
  generateMetrics,
} from "../services/signatureServiceNew";
import { testInput } from "../services/testService";
import { makePretty } from "../services/textOutput";
import { version } from "./../config";
import { checkEndpointStatus, getEsiStatus } from "./../services/esiService";
import { convertToTripWireObjects } from "./../services/tripwireService";
import EasterEggBox from "./easterEggBox";
import ErrorsBox from "./errors";
import StatusCircle from "./statusCircle";
import WormholeDisplay from "./wormholeDisplay";
function CSVInput() {
  const param = new URLSearchParams(window.location.search);

  const systemParam = param.get("system");

  let [systemNameStorage, setSystemNameStorage] = useStickyState(
    null,
    "systemName"
  );

  // Updates the state of the ESIStatusData
  useEffect(() => {
    if (systemParam) setSystemNameStorage(systemParam);
  }, []);

  // "J125209"
  let [systemName, setSystemName] = useState(
    systemParam || systemNameStorage || ""
  );

  // Inputs, change for testing

  let [testMode] = useStickyState(false, "testMode");

  let defaultInput = testMode ? testInput : "";
  let errorInput = testMode ? errorTestInput : "";

  let [input, setInput] = useState(defaultInput);

  let [output, setOutput] = useState("");

  let [errorsArray, setErrors] = useState<string[]>([]);

  let [esiStatusData, setEsiStatusData] = useState<esiStatus[]>([]);
  let [esiResolved, setEsiResolved] = useState(false);

  let [esiDisplay, setEsiDisplay] = useState("pending");
  let [endpointsDisplay, setEndPointsDisplay] = useState("pending");

  // Loading
  let [loading, setLoading] = useState(false);

  // Toasted Info
  const toast = useToast();

  // Updates the state of the ESIStatusData
  useEffect(() => {
    async function esiStatus() {
      try {
        const status = await getEsiStatus();
        setEsiStatusData(status);
        setEsiResolved(true);
      } catch (error) {
        setEsiResolved(true);
      }
    }

    esiStatus();
  }, []);

  // Sets esiDisplay
  useEffect(() => {
    if (esiResolved === true) {
      if (esiStatusData.length !== 0) {
        setEsiDisplay("up");
      } else if (esiResolved === true) {
        setEsiDisplay("down");
      }
    }
  }, [esiResolved]);

  // Sets endpointsDisplay
  useEffect(() => {
    if (esiResolved === true) {
      if (esiStatusData.length === 0) {
        setEndPointsDisplay("down");
        return;
      }

      if (!checkEndpointStatus(esiStatusData)) {
        setEndPointsDisplay("down");
        return;
      }
      setEndPointsDisplay("up");
    }
  }, [esiResolved]);

  // Raises Toast if issues.

  useEffect(() => {
    if (esiResolved === true) {
      if (endpointsDisplay === "down" || esiDisplay === "down") {
        toast({
          title: "EVE ESI is having issues, please try again later",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  }, [endpointsDisplay, esiDisplay]);

  let handleInputChange = (e: { target: { value: any } }) => {
    let inputValue = e.target.value;
    setInput(inputValue);
  };

  let handleSystemChange = (e: { target: { value: any } }) => {
    let inputValue = e.target.value;
    setSystemName(inputValue);
    setSystemNameStorage(inputValue);
  };

  const handleConvert = async () => {
    setLoading(true);

    // @ts-ignore
    const result: { data: []; errors: []; meta: unknown } =
      convertCsvToObject(input);

    if (result.errors.length > 0) {
      result.errors.forEach((error: { message: any }) =>
        toast({
          title: "Error with Input",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        })
      );
      setLoading(false);
      return;
    }

    const tripWireObjectArray = convertToTripWireObjects(result.data);

    const inputErrors = checkInput(tripWireObjectArray);

    setErrors(inputErrors);

    if (inputErrors.length > 0) {
      setLoading(false);
      return;
    }

    // Easter Eggs
    const eggs = generateEasterEggs(tripWireObjectArray);
    handleEasterEggs(eggs);

    const arrayOfSignatures = await generateArrayOfSignatures(
      tripWireObjectArray
    );

    const metricsOutput = generateMetrics(arrayOfSignatures);

    if (testMode) {
      console.log("==========");
      console.log("Test Mode");
      console.log("==========");
      console.log("tripwire", tripWireObjectArray);
      console.log("array", arrayOfSignatures);
      console.log("metrics", metricsOutput);
      console.log("==========");
    }

    const prettyOutput = makePretty(metricsOutput, systemName);

    setOutput(prettyOutput);
    setLoading(false);
  };

  const handleEasterEggs = (easterEggs) => {
    const diceSides = 12;
    const dice = Math.floor(Math.random() * diceSides) + 1;

    console.log("You rolled", dice);

    if (dice !== diceSides) {
      console.log(
        `Unlucky Roll! You need a ${diceSides}! and maybe something else...`
      );
      return;
    }

    const positions: ToastPosition[] = [
      "top",
      "top-right",
      "top-left",
      "bottom-right",
      "bottom-left",
    ];

    if (easterEggs.length > 0) {
      for (const egg of easterEggs) {
        let position = Math.round(Math.random() * (positions.length - 1));

        let randomPosition: ToastPosition = positions[position];

        toast({
          position: randomPosition,
          duration: 5000,
          isClosable: false,
          render: () => <EasterEggBox egg={egg} />,
        });
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(output);
    toast({
      title: "Copied to Clipboard!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Fragment>
      <Box mt="1rem">
        <Flex alignItems="center">
          <Image mr="1rem" src="/favicon.png"></Image>

          <Heading>TripWire to MOTD Converter</Heading>

          <Spacer />

          <Flex gap={2} marginY={3}>
            <StatusCircle tooltip="ESI" status={esiDisplay} />
            <StatusCircle tooltip="Endpoints" status={endpointsDisplay} />
          </Flex>
        </Flex>
        {/* <EasterEggBox egg={"Hey Sherbs"} /> */}
        <Divider my="1rem" />
        <Flex>
          <Input
            width="200px"
            placeholder="System Name"
            onChange={handleSystemChange}
            value={systemName}
          />
        </Flex>
        <Divider my="1rem" />
        <Heading size="lg" mb="1rem">
          Paste TripWire Sigs Below:
        </Heading>
        <Textarea
          height={"10rem"}
          value={input}
          onChange={handleInputChange}
          placeholder="In TripWire: click in the Signature Window, then hit CTRL+A (to select all), then CTRL+C (to copy) and CTRL+V (paste) that data here."
          size="sm"
        />
        <HStack mt={2}>
          <Button
            isLoading={loading}
            onClick={() => handleConvert()}
            colorScheme="green"
          >
            Convert
          </Button>{" "}
          {input !== "" ? (
            <Button onClick={() => setInput("")} colorScheme="red">
              Clear
            </Button>
          ) : null}
        </HStack>
      </Box>
      <ErrorsBox errors={errorsArray} />
      <Divider my={5} />
      <Box>
        <Heading size="lg" mb="1rem">
          Copy the below and paste it as the MOTD
        </Heading>
        <Textarea
          height={"10rem"}
          value={output}
          readOnly
          placeholder="Once you've pasted in the above box and clicked Convert, this will display the text to paste into the MOTD"
        />
        <Box textAlign={"right"}>
          {/* {output.length} / <strong>4000 (MOTD Max)</strong> */}
          {version}
        </Box>
        <HStack mt={2}>
          {output !== "" ? (
            <Button onClick={handleCopy} colorScheme="green">
              Copy to Clipboard
            </Button>
          ) : null}
          {output !== "" ? (
            <Button onClick={() => setOutput("")} colorScheme="red">
              Clear
            </Button>
          ) : null}
        </HStack>
      </Box>

      <WormholeDisplay output={output} />
    </Fragment>
  );
}

export default CSVInput;
