// time format

const users = [
  "Sherberetta",
  "Telchek Ernaga",
  "Jabari Shepard",
  "Rick Aldeland",
  "Shinra Shimitsu",
];

function rDigits(amount: number) {
  const digits = "1234567890";

  let str = "";

  for (let i = 0; i < amount; i++) {
    const r = Math.round(Math.random() * 9);
    const letter = digits[r];
    str += letter;
  }

  return str;
}

function rLetters(amount: number) {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let str = "";

  for (let i = 0; i < amount; i++) {
    const r = Math.round(Math.random() * 25);
    const letter = letters[r];
    str += letter;
  }

  return str;
}

function sigGen() {
  return `${rLetters(3)}-${rDigits(3)}`;
}

function rUser() {
  const no = Math.round(Math.random() * users.length - 1);
  return users[no];
}

const statics = [
  `${sigGen()},wormhole,N110,J125209,Barmalie,critical,destab,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
  `${sigGen()},wormhole,J244,J125209,Tama,critical,critical,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
  `${sigGen()},wormhole,Z647,J125209,F9E-KX,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
];

const wandering = [
  `${sigGen()},wormhole,H121,J125209,J100744,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
  `${sigGen()},wormhole,C125,J125209,J101524,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-16 11:31:30`,
  `AYJ-784,wormhole,Z971,J125209,GDO-7H,stable,stable,Sherberetta,2022-12-31 12:54:26,57600,2023-01-01 04:54:26,Sherberetta,2022-12-31 13:07:34`,
];

const frigates = [
  `${sigGen()},wormhole,E004,J125209,J100250,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
  `${sigGen()},wormhole,L005,J125209,J222822,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-16 11:31:30`,
];

const drifters = [
  `${sigGen()},wormhole,S877,J125209,J055520,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
  `${sigGen()},wormhole,B735,J125209,J110145,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-16 11:31:30`,
];

const shattered = [
  `${sigGen()},wormhole,A009,J125209,J000621,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
];

const exits = [
  `${sigGen()},wormhole,K162,J125209,J124508,stable,stable,${rUser()},2022-12-14 18:29:07,86400,2022-12-15 18:29:07,${rUser()},2022-12-14 18:29:30`,
];

const gas = [
  `${sigGen()},gas,Generic Gas Name,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
];
const ore = [
  `${sigGen()},ore,Common Perimeter Deposit,${rUser()},2022-12-14 17:37:42,259200,2022-12-17 17:37:42,${rUser()},2022-12-14 17:37:42`,
];
const relic = [
  `${sigGen()},relic,Generic Relic Name 1,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},relic,Generic Relic Name 2,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},relic,Generic Relic Name 3,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},relic,Generic Relic Name 4,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
];

const data = [
  `${sigGen()},data,Generic Data Name 1,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},data,Generic Data Name 2,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},data,Generic Data Name 3,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
  `${sigGen()},data,Generic Data Name 4,${rUser()},2022-12-14 20:19:31,259200,2022-12-17 20:19:31,${rUser()},2022-12-14 20:19:53`,
];

const combat = [
  `${sigGen()},combat,Phase Catalyst Node,${rUser()},2023-01-15 14:15:41,259200,2023-01-18 14:15:41,${rUser()},2023-01-15 14:15:41`,
  `${sigGen()},combat,Perimeter Ambush Point,${rUser()},2023-01-15 14:15:41,259200,2023-01-18 14:15:41,${rUser()},2023-01-15 14:15:41`,
];

function createTestInput() {
  const output = [
    ...statics,
    ...wandering,
    ...frigates,
    ...drifters,
    ...shattered,
    ...exits,
    ...gas,
    ...ore,
    ...relic,
    ...data,
    ...combat,
  ];

  return output.join("\n");
}

// const test2 = `XTX-836,data,Central Guristas Command Center,Sherberetta,2022-12-18 10:23:34,259200,2022-12-21 10:23:34,Sherberetta,2022-12-18 10:23:54
// ZED-285,relic,Ruined Sansha Monument Site,Sherberetta,2022-12-18 15:04:21,259200,2022-12-21 15:04:21,Sherberetta,2022-12-18 15:07:45
// DEQ-335,relic,Ruined Serpentis Monument Site,Sherberetta,2022-12-17 20:51:52,259200,2022-12-20 20:51:52,Sherberetta,2022-12-17 20:54:01
// HFV-083,relic,Ruined Serpentis Temple Site,Sherberetta,2022-12-19 11:52:33,259200,2022-12-22 11:52:33,Sherberetta,2022-12-19 11:57:08
// HMM-480,ore,Unexceptional Frontier Deposit,Sherberetta,2022-12-16 18:32:18,259200,2022-12-19 18:32:18,Sherberetta,2022-12-16 18:32:18
// NMY-510,ore,Unexceptional Frontier Deposit,Sherberetta,2022-12-18 14:48:03,259200,2022-12-21 14:48:03,Sherberetta,2022-12-18 14:48:03
// UNN-482,wormhole,????,J125209,J145634,stable,stable,Sherberetta,2022-12-19 11:52:33,259200,2022-12-22 11:52:33,Sherberetta,2022-12-19 12:01:12
// JBH-000,wormhole,????,J125209,J223538,stable,stable,Sherberetta,2022-12-18 22:15:42,259200,2022-12-21 22:15:42,Sherberetta,2022-12-18 22:17:53
// QCV-400,wormhole,N110,J125209,Remoriu,stable,stable,Sherberetta,2022-12-19 11:52:33,86400,2022-12-20 11:52:33,Sherberetta,2022-12-19 11:59:12
// UGF-916,wormhole,????,J125209,Tidacha,stable,stable,Sherberetta,2022-12-19 11:52:33,259200,2022-12-22 11:52:33,Sherberetta,2022-12-19 12:02:48
// `;

export const testInput = createTestInput();
// const testInput = test2;

// createRegExp("Ruined (Faction) Crystal Quarry");
// returnSiteGrade("Central Guristas Data Mining Site");
