import {
  TripWireCSV,
  TripWireSite,
  TripWireWormhole,
} from "../interfaces/tripwire";

export function convertToTripWireObjects(tripWireArray: TripWireCSV) {
  const returnArray: (TripWireSite | TripWireWormhole)[] = [];

  // wormholes have length of 13
  // sites have length of 9

  for (const key in tripWireArray) {
    const value = tripWireArray[key];

    if (value.length === 13) {
      const wormHole: TripWireWormhole = {
        id: value[0],
        type: value[1],
        wormholeType: value[2],
        location: value[3],
        destination: value[4],
        life: value[5],
        mass: value[6],
        createdByUser: value[7],
        createdTime: new Date(value[8]),
        lifeTimeInSeconds: value[9],
        dateOfDeath: new Date(value[10]),
        lastModifiedByUser: value[11],
        lastModifiedTime: new Date(value[12]),
      };

      returnArray.push(wormHole);
    } else if (value.length === 9) {
      const site: TripWireSite = {
        id: value[0],
        type: value[1],
        name: value[2],
        createdByUser: value[3],
        createdTime: new Date(value[4]),
        lifeTimeInSeconds: value[5],
        dateOfDeath: new Date(value[6]),
        lastModifiedByUser: value[7],
        lastModifiedTime: new Date(value[8]),
      };

      returnArray.push(site);
    }
  }

  return returnArray;
}
