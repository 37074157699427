import { loc, respawn, whList, Wormhole } from "./wormholeData";

export const wormHoleTypes = (type: string) => {
  const hash: { [name: string]: Wormhole[] } = {
    static: staticData(),
    wandering: wanderingData(),
    static_wanderer: staticWanderingData(),
    exit: exitData,
    frigate: frigateData(),
    drifter: drifterData(),
  };

  if (!Object.keys(hash).includes(type)) {
    throw new Error(`Type Error: '${type}' type of wormhole does not exist`);
  }

  const data: Wormhole[] = hash[type];

  const array: string[] = [];

  for (const wh of data) {
    array.push(wh.code);
  }

  return array;
};

const staticData = (): Wormhole[] => {
  // Generates an Array of Static Wormholes

  const statics: Wormhole[] = [];

  for (const wh of whList) {
    if (wh.respawn === "STATIC") statics.push(wh);
  }
  return statics;
};

const staticWanderingData = () => {
  // Generates an Array of Static/Wandering Wormholes
  const wandering: Wormhole[] = [];

  for (const wh of whList) {
    if (wh.respawn === "STATIC_WANDERER") wandering.push(wh);
  }

  return wandering;
};

const wanderingData = () => {
  // Generates an Array of Wandering Wormholes
  // Cannot have any special attributes - covers Frigate holes
  const wandering: Wormhole[] = [];

  for (const wh of whList) {
    if (wh.respawn === "WANDERING" && !wh.special) wandering.push(wh);
  }

  return wandering;
};

const frigateData = () => {
  const frigate: Wormhole[] = [];

  for (const wh of whList) {
    if (wh.special === "FRIGATE") frigate.push(wh);
  }

  return frigate;
};
const drifterData = () => {
  const drifter: Wormhole[] = [];

  for (const wh of whList) {
    if (wh.special === "DRIFTER") drifter.push(wh);
  }

  return drifter;
};

const exitData: Wormhole[] = [
  new Wormhole("K162", loc.ANY, loc.EXIT, respawn.UNKNOWN),
];
