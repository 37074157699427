import {
  SigData,
  Signature as ISignature,
  Wormhole,
} from "../interfaces/signature";

export class Signature implements ISignature {
  sigData: SigData;
  // siteURL?: string;
  wormHole?: Wormhole;

  constructor(sigData: SigData) {
    this.sigData = sigData;
  }
}
