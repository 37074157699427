import {
  Box,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BiErrorCircle } from "react-icons/bi";

function ErrorsBox(props: { errors: string[] }) {
  const { errors } = props;

  if (errors.length === 0) {
    return <></>;
  }

  return (
    <Box
      fontWeight="600"
      borderRadius={".5rem"}
      p={3}
      border="1px solid grey"
      my={"1rem"}
    >
      <Heading size={"md"} color={"red.600"}>
        Errors
      </Heading>
      <Divider my={".5rem"} />
      <Text>Fix the below to continue</Text>
      <Divider my={".5rem"} />
      <List spacing={3}>
        {errors.map((error, index) => (
          <ListItem key={index}>
            <ListIcon as={BiErrorCircle} color="red.600" /> {error}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ErrorsBox;
