import { siteURL } from "../config";
import { Metrics, TradeRoutes } from "../interfaces/output";
import { Signature } from "./../models/signature";
import { colors } from "./constants";
import { cleanHTML } from "./htmlService";
import { returnSiteGrade } from "./relicAndDataService";
import { eveSurvivalLink } from "./signatureServiceNew";
import { convertToUTC } from "./timeService";

export const makePretty = (metrics: Metrics, systemName: string) => {
  const results = outputText(metrics, systemName);
  let combined = cleanHTML(results);

  return combined;
};

// Generate HTML
const generateSignatureList = (
  name: string,
  sigs: Signature[],
  colour: string
) => {
  // If no sigs, then we do nothing :)
  if (!sigs) return "";

  const header = `<font color="${colour}"><b>${sigs.length} x ${name}:</b></font><br/>`;

  type siteNamesT = {
    [name: string]: string[];
  };

  const siteNames: siteNamesT = {};

  // If we have multiple sites of the same name lets batch them together.
  for (const sig of sigs) {
    // Don't bother with wormholes
    if (sig.wormHole) continue;

    if (sig.sigData.name in siteNames) {
      siteNames[sig.sigData.name].push(sig.sigData.id);
    } else {
      siteNames[sig.sigData.name] = [sig.sigData.id];
    }
  }

  const siteTypesArray = [];

  for (const [name, array] of Object.entries(siteNames)) {
    siteTypesArray.push({ name: name, ids: array.join(", ") });
  }

  // If siteTypesArray has stuff, then just return it.
  if (siteTypesArray.length > 0) {
    const output = siteTypesArray
      .map(
        (sig) =>
          `${sig.ids} - <a href="${eveSurvivalLink(sig.name)}">${
            sig.name
          }</a> ${returnSiteGrade(sig.name)}<br/>`
      )
      .join("");

    return `${header}${output}---<br/>`;
  }

  // Only triggers if siteTypesArray is Empty, so Wormholes.

  const output = sigs
    .map((sig) => {
      const id = sig.sigData.id;
      const system = `<a href="showinfo:5//${sig.wormHole.destination.systemID}">
${sig.wormHole.destination.systemName}
</a>`;

      const classOrSec =
        sig.wormHole.destination.wormholeClass !== null
          ? sig.wormHole.destination.wormholeClass
          : sig.wormHole.destination.secStatus;

      const info = `${classOrSec} / ${sig.wormHole.wormholeType}`;

      const massStable = sig.wormHole.mass === "stable" ? true : false;
      const lifeStable = sig.wormHole.life === "stable" ? true : false;

      const massColour = sig.wormHole.mass === "destab" ? "orange" : "red";

      const eolStatus = !lifeStable ? `(<font color="red">EOL</font>)` : "";
      const massStatus = !massStable
        ? `mass: <font color="${massColour}">${sig.wormHole.mass}</font>`
        : "";

      return `${id} - ${eolStatus}  ${system} (${info}) ${massStatus} <br>`;
    })
    .join("");

  return `${header}${output}---<br/>`;
};

const generateTradeRoutes = (tradeRoute: TradeRoutes) => {
  const output = [];

  for (const key in tradeRoute) {
    const sig: Signature = tradeRoute[key];
    const jumps: number = sig.wormHole.destination.tradeHubs[key];
    const id: string = sig.sigData.id.slice(0, 3);
    const system: string = sig.wormHole.destination.systemName;

    const str = `${key} - ${jumps} Jumps via ${system} (${id})<br/>`;

    output.push(str);
  }

  return output.join("");
};

// prettier-ignore
const outputText =  (metrics: Metrics, systemName: string) => {

  const wormholes = [
    { name: 'Static', data: metrics.static, colour:colors.Cyan },
    { name: 'Wandering', data: metrics.wandering, colour: colors.Cyan },
    { name: 'Static/Wandering', data: metrics.static_wanderer, colour: colors.Cyan },
    { name: 'Frigates', data: metrics.frigate, colour: colors.Cyan },
    { name: 'Drifter', data: metrics.drifter, colour: colors.Cyan },
    { name: 'Exits', data: metrics.exit, colour:colors.Cyan },
  ];

  const sites = [
    { name: 'Ore', data: metrics.ore, colour: colors.Green },
    { name: 'Gas', data: metrics.gas, colour: colors.Green },
    { name: 'Relic', data: metrics.relic, colour: colors.Yellow },
    { name: 'Data', data: metrics.data, colour: colors.Yellow },
    { name: 'Combat', data: metrics.combat, colour: colors.Red }
  ]

  return `<font size="13">${systemName} 
    <url=https://tripwiremap.app/?system=${systemName}>(TripWire)</url>
    <br/><br />
    
    Last Updated by ${metrics.lastUser}
    <br/>
    on ${convertToUTC(metrics.lastModified)} (Eve Time)
    <br/><br/>

    ${wormholes.map((wh)=> generateSignatureList(wh.name,wh.data,wh.colour)).join('')}
  
    ${generateTradeRoutes(metrics.tradeRoutes)}
    ---
    <br/>
    ${sites.map((sig) => generateSignatureList(sig.name, sig.data, sig.colour)).join('')}
    Used 3rd party sites:
    <br/>
    <url=${siteURL}?system=${systemName} >TripWire to MOTD Converter</url>
    <br/>
    <url=http://anoik.is/systems/${systemName}>Anoik.is</url>
    `
}
