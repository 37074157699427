import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

import { GiEasterEgg } from "react-icons/gi";

function EasterEggBox(props: { egg: string }) {
  const { egg } = props;
  return (
    <Flex
      margin={"0.5rem"}
      className="rainbow"
      fontWeight="600"
      color="black"
      borderRadius={".5rem"}
      p={3}
    >
      <Icon as={GiEasterEgg} boxSize={6} color={"white"} />
      <Text marginLeft={"0.5rem"}>{egg}</Text>
    </Flex>
  );
}

export default EasterEggBox;
