export enum loc {
  HS = "HS",
  LS = "LS",
  NS = "NS",
  HS_LS_NS = "HS|LS|NS",
  LS_NS = "LS|NS",
  C1 = "C1",
  C2 = "C2",
  C2_C3 = "C2|C3",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  C13 = "C13",
  C14 = "C14",
  C15 = "C15",
  C16 = "C16",
  C17 = "C17",
  C18 = "C18",
  THERA = "Thera",
  EXIT = "Exit",
  ANY = "Any",
}

export enum respawn {
  STATIC = "STATIC",
  WANDERING = "WANDERING",
  STATIC_WANDERER = "STATIC_WANDERER",
  UNKNOWN = "UNKNOWN",
}

// Static Wanderers
// H900 x
// R474 x
// U574 x
// V753 x
// N062 x
// V911 x
// D382 x
// W237 x
// X877 x
// Y683 x
// E175 x
// O477 x
// Z457 x
// Z647 x
// H296 x

export enum special {
  FRIGATE = "FRIGATE",
  DRIFTER = "DRIFTER",
}

export class Wormhole {
  constructor(
    public code: string,
    public source: loc,
    public dest: loc,
    public respawn: string,
    public special?: special
  ) {}
}

const c1 = [
  new Wormhole("N110", loc.C1, loc.HS, respawn.STATIC),
  new Wormhole("J244", loc.C1, loc.LS, respawn.STATIC),
  new Wormhole("Z060", loc.C1, loc.NS, respawn.STATIC),
  new Wormhole("H121", loc.C1, loc.C1, respawn.WANDERING),
  new Wormhole("C125", loc.C1, loc.C2, respawn.WANDERING),
  new Wormhole("O883", loc.C1, loc.C3, respawn.WANDERING),
  new Wormhole("M609", loc.C1, loc.C4, respawn.WANDERING),
  new Wormhole("L614", loc.C1, loc.C5, respawn.WANDERING),
  new Wormhole("S804", loc.C1, loc.C6, respawn.WANDERING),
  new Wormhole("F353", loc.C1, loc.THERA, respawn.WANDERING),
];

const c2 = [
  new Wormhole("B274", loc.C2, loc.HS, respawn.STATIC),
  new Wormhole("A239", loc.C2, loc.LS, respawn.STATIC),
  new Wormhole("E545", loc.C2, loc.NS, respawn.STATIC),
  new Wormhole("Z647", loc.C2, loc.C1, respawn.STATIC_WANDERER),
  new Wormhole("D382", loc.C2, loc.C2, respawn.STATIC_WANDERER),
  new Wormhole("O477", loc.C2, loc.C3, respawn.STATIC_WANDERER),
  new Wormhole("Y683", loc.C2, loc.C4, respawn.STATIC_WANDERER),
  new Wormhole("N062", loc.C2, loc.C5, respawn.STATIC_WANDERER),
  new Wormhole("R474", loc.C2, loc.C6, respawn.STATIC_WANDERER),
];

const c2_c3 = [new Wormhole("F135", loc.C2_C3, loc.THERA, respawn.STATIC)];

const c3 = [
  new Wormhole("D845", loc.C3, loc.HS, respawn.STATIC),
  new Wormhole("U210", loc.C3, loc.LS, respawn.STATIC),
  new Wormhole("K346", loc.C3, loc.NS, respawn.STATIC),
  new Wormhole("V301", loc.C3, loc.C1, respawn.WANDERING),
  new Wormhole("I182", loc.C3, loc.C2, respawn.WANDERING),
  new Wormhole("N968", loc.C3, loc.C3, respawn.WANDERING),
  new Wormhole("T405", loc.C3, loc.C4, respawn.WANDERING),
  new Wormhole("N770", loc.C3, loc.C5, respawn.WANDERING),
  new Wormhole("A982", loc.C3, loc.C6, respawn.WANDERING),
];

const c4 = [
  new Wormhole("P060", loc.C4, loc.C1, respawn.STATIC),
  new Wormhole("N766", loc.C4, loc.C2, respawn.STATIC),
  new Wormhole("C247", loc.C4, loc.C3, respawn.STATIC),
  new Wormhole("X877", loc.C4, loc.C4, respawn.STATIC_WANDERER),
  new Wormhole("H900", loc.C4, loc.C5, respawn.STATIC_WANDERER),
  new Wormhole("U574", loc.C4, loc.C6, respawn.STATIC_WANDERER),
  new Wormhole("S047", loc.C4, loc.HS, respawn.WANDERING),
  new Wormhole("N290", loc.C4, loc.LS, respawn.WANDERING),
  new Wormhole("K329", loc.C4, loc.NS, respawn.WANDERING),
];

const c5 = [
  new Wormhole("Y790", loc.C5, loc.C1, respawn.STATIC),
  new Wormhole("D364", loc.C5, loc.C2, respawn.STATIC),
  new Wormhole("M267", loc.C5, loc.C3, respawn.STATIC),
  new Wormhole("E175", loc.C5, loc.C4, respawn.STATIC_WANDERER),
  new Wormhole("H296", loc.C5, loc.C5, respawn.STATIC_WANDERER),
  new Wormhole("V753", loc.C5, loc.C6, respawn.STATIC_WANDERER),
  new Wormhole("D792", loc.C5, loc.HS, respawn.WANDERING),
  new Wormhole("C140", loc.C5, loc.LS, respawn.WANDERING),
  new Wormhole("Z142", loc.C5, loc.NS, respawn.WANDERING),
];

const c6 = [
  new Wormhole("Q317", loc.C6, loc.C1, respawn.STATIC),
  new Wormhole("G024", loc.C6, loc.C2, respawn.STATIC),
  new Wormhole("L477", loc.C6, loc.C3, respawn.STATIC),
  new Wormhole("Z457", loc.C6, loc.C4, respawn.STATIC_WANDERER),
  new Wormhole("V911", loc.C6, loc.C5, respawn.STATIC_WANDERER),
  new Wormhole("W237", loc.C6, loc.C6, respawn.STATIC_WANDERER),
  new Wormhole("B520", loc.C6, loc.HS, respawn.WANDERING),
  new Wormhole("D792", loc.C6, loc.HS, respawn.WANDERING),
  new Wormhole("C140", loc.C6, loc.LS, respawn.WANDERING),
  new Wormhole("C391", loc.C6, loc.LS, respawn.WANDERING),
  new Wormhole("C248", loc.C6, loc.NS, respawn.WANDERING),
  new Wormhole("Z142", loc.C6, loc.NS, respawn.WANDERING),
];

const drifter = [
  new Wormhole("S877", loc.ANY, loc.C14, respawn.UNKNOWN, special.DRIFTER), // Sentinel
  new Wormhole("B735", loc.ANY, loc.C15, respawn.UNKNOWN, special.DRIFTER), // Barbican
  new Wormhole("V928", loc.ANY, loc.C16, respawn.UNKNOWN, special.DRIFTER), // Vidette
  new Wormhole("C414", loc.ANY, loc.C17, respawn.UNKNOWN, special.DRIFTER), // Conflux
  new Wormhole("R259", loc.ANY, loc.C18, respawn.UNKNOWN, special.DRIFTER), // Redoubt
];

const frigates = [
  new Wormhole("E004", loc.ANY, loc.C1, respawn.WANDERING, special.FRIGATE),
  new Wormhole("L005", loc.ANY, loc.C2, respawn.WANDERING, special.FRIGATE),
  new Wormhole("Z006", loc.ANY, loc.C3, respawn.WANDERING, special.FRIGATE),
  new Wormhole("M001", loc.ANY, loc.C4, respawn.WANDERING, special.FRIGATE),
  new Wormhole("C008", loc.ANY, loc.C5, respawn.WANDERING, special.FRIGATE),
  new Wormhole("G008", loc.ANY, loc.C6, respawn.WANDERING, special.FRIGATE),
  new Wormhole("Q003", loc.ANY, loc.NS, respawn.WANDERING, special.FRIGATE),
  new Wormhole("A009", loc.ANY, loc.C13, respawn.WANDERING, special.FRIGATE), // Shattered
];

const hs = [
  new Wormhole("M555", loc.HS, loc.C5, respawn.WANDERING),
  new Wormhole("B041", loc.HS, loc.C6, respawn.WANDERING),
  new Wormhole("A641", loc.HS, loc.HS, respawn.WANDERING),
  new Wormhole("R051", loc.HS, loc.LS, respawn.WANDERING),
  new Wormhole("V283", loc.HS, loc.NS, respawn.WANDERING),
  new Wormhole("T458", loc.HS, loc.THERA, respawn.WANDERING),
];

const ls = [new Wormhole("M164", loc.LS, loc.THERA, respawn.WANDERING)];
const ns = [new Wormhole("L031", loc.NS, loc.THERA, respawn.WANDERING)];

const hs_ls_ns = [
  new Wormhole("Z971", loc.HS_LS_NS, loc.C1, respawn.WANDERING),
  new Wormhole("R943", loc.HS_LS_NS, loc.C2, respawn.WANDERING),
  new Wormhole("X702", loc.HS_LS_NS, loc.C3, respawn.WANDERING),
  new Wormhole("O128", loc.HS_LS_NS, loc.C4, respawn.WANDERING),
];

const ls_ns = [
  new Wormhole("N432", loc.LS_NS, loc.C5, respawn.WANDERING),
  new Wormhole("U319", loc.LS_NS, loc.C6, respawn.WANDERING),
  new Wormhole("B449", loc.LS_NS, loc.HS, respawn.WANDERING),
  new Wormhole("N944", loc.LS_NS, loc.LS, respawn.WANDERING),
  new Wormhole("S199", loc.LS_NS, loc.NS, respawn.WANDERING),
];

const thera = [
  new Wormhole("Q063", loc.THERA, loc.HS, respawn.STATIC),
  new Wormhole("V898", loc.THERA, loc.LS, respawn.STATIC),
  new Wormhole("E587", loc.THERA, loc.NS, respawn.STATIC),
];

export const whList = [
  ...c1,
  ...c2,
  ...c2_c3,
  ...c3,
  ...c4,
  ...c5,
  ...c6,
  ...drifter,
  ...frigates,
  ...hs,
  ...ls,
  ...ns,
  ...hs_ls_ns,
  ...ls_ns,
  ...thera,
];

export const fullListOfCodes = [
  "H121",
  "C125",
  "O883",
  "M609",
  "L614",
  "S804",
  "N110",
  "J244",
  "Z060",
  "F353",
  "Z647",
  "D382",
  "O477",
  "Y683",
  "N062",
  "R474",
  "B274",
  "A239",
  "E545",
  "V301",
  "I182",
  "N968",
  "T405",
  "N770",
  "A982",
  "D845",
  "U210",
  "K346",
  "F135",
  "P060",
  "N766",
  "C247",
  "X877",
  "H900",
  "U574",
  "S047",
  "N290",
  "K329",
  "Y790",
  "D364",
  "M267",
  "E175",
  "H296",
  "V753",
  "D792",
  "C140",
  "Z142",
  "Q317",
  "G024",
  "L477",
  "Z457",
  "V911",
  "W237",
  "B520",
  "D792",
  "C140",
  "C391",
  "C248",
  "Z142",
  "Z971",
  "R943",
  "X702",
  "O128",
  "M555",
  "B041",
  "A641",
  "R051",
  "V283",
  "T458",
  "Z971",
  "R943",
  "X702",
  "O128",
  "N432",
  "U319",
  "B449",
  "N944",
  "S199",
  "M164",
  "Z971",
  "R943",
  "X702",
  "O128",
  "N432",
  "U319",
  "B449",
  "N944",
  "S199",
  "L031",
  "Q063",
  "V898",
  "E587",
  "E004",
  "L005",
  "Z006",
  "M001",
  "C008",
  "G008",
  "Q003",
  "A009",
  "S877",
  "B735",
  "V928",
  "C414",
  "R259",
];

function checkMissingWormholes(set: string[], have: string[]) {
  const remaining: string[] = [];

  for (const each of set) {
    if (!have.includes(each)) {
      if (!remaining.includes(each)) remaining.push(each);
    }
  }

  if (remaining.length !== 0) {
    throw new Error(`Missing Wormholes! ${remaining}`);
  }

  const wrongCodes = checkNoWrongCodes(set, have);
  if (wrongCodes.length !== 0) {
    throw new Error(`Wrong Codes detected! ${wrongCodes}`);
  }

  return remaining;
}

function createWormholeList(whList: Wormhole[]): string[] {
  const codes = [];

  for (const wh of whList) {
    codes.push(wh.code);
  }

  return codes;
}

function checkNoWrongCodes(set: string[], have: string[]) {
  const wrongCodes = [];

  for (const code of have) {
    if (!set.includes(code)) wrongCodes.push(code);
  }

  return wrongCodes;
}

// console.log(checkMissingWormholes(fullListOfCodes, createWormholeList(whList)));
