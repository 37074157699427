import { TripWireSite, TripWireWormhole } from "../interfaces/tripwire";

function randomGreeting(dudeName) {
  const greetings = [
    (dudeName) => `Oh hey there, ${dudeName}`,
    (dudeName) => `Salutations ${dudeName}`,
    (dudeName) => `${dudeName}! Howzit?`,
    (dudeName) => `${dudeName}, What's the rumpus?`,
    (dudeName) => `${dudeName}! How you doin?`,
    (dudeName) => `Peace out, ${dudeName}`,
    (dudeName) => `${dudeName}, how's it hanging?`,
    (dudeName) => `Sup ${dudeName}`,
    (dudeName) => `What's the dizzle ${dudeName}?`,
    (dudeName) => `What's crackin' ${dudeName}?`,
  ];

  const index = Math.round(Math.random() * (greetings.length - 1));

  const reply = greetings[index](dudeName);

  return reply;
}

export function generateEasterEggs(
  sigs: (TripWireSite | TripWireWormhole)[]
): string[] {
  const coolDudes = [
    "Sherberetta",
    "Telchek Ernaga",
    "Jabari Shepard",
    "Rick Aldeland",
    "Shinra Shimitsu",
  ];

  const dudesFound = [];

  for (const sig of sigs) {
    if (
      coolDudes.includes(sig.createdByUser) &&
      !dudesFound.includes(sig.createdByUser)
    ) {
      dudesFound.push(sig.createdByUser);
    }
  }

  const eggs = [];

  for (const dude of dudesFound) {
    eggs.push(`${randomGreeting(dude)}`);
  }

  return eggs;
}
