// time format

const users = ["Sherberetta", "Telchek Ernaga", "Jabari Shepard"];

function rDigits(amount: number) {
  const digits = "1234567890";

  let str = "";

  for (let i = 0; i < amount; i++) {
    const r = Math.round(Math.random() * 9);
    const letter = digits[r];
    str += letter;
  }

  return str;
}

function rLetters(amount: number) {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let str = "";

  for (let i = 0; i < amount; i++) {
    const r = Math.round(Math.random() * 25);
    const letter = letters[r];
    str += letter;
  }

  return str;
}

function sigGen() {
  return `${rLetters(3)}-${rDigits(3)}`;
}

function rUser() {
  const no = Math.round(Math.random() * 2);
  return users[no];
}

const rawInput = [
  `ZWQ-790,unknown,,Sarkal Anthar,2023-04-27 18:45:08,259200,2023-04-30 18:45:08,Sarkal Anthar,2023-04-27 18:45:08`,
];

const unTypedWormhole = [
  `FSK-773,wormhole,null,Jita,,stable,stable,Sarkal Anthar,2023-04-27 18:45:08,259200,2023-04-30 18:45:08,Sarkal Anthar,2023-04-27 19:08:48`,
];

const unamedSig = [
  `PKG-880,data,,Sarkal Anthar,2023-04-27 18:45:08,259200,2023-04-30 18:45:08,Sarkal Anthar,2023-04-27 19:23:22`,
];

const wrongLeadsTo = [
  `FSK-773,wormhole,K162,Jita,Null-Sec,stable,stable,Sarkal Anthar,2023-04-27 18:45:08,86400,2023-04-28 18:45:08,Sarkal Anthar,2023-04-27 19:29:07`,
  `ZWQ-790,wormhole,C140,Jita,Low-Sec,stable,stable,Sarkal Anthar,2023-04-27 18:45:08,86400,2023-04-28 18:45:08,Sarkal Anthar,2023-04-27 19:26:50`,
  `ZWQ-790,wormhole,C140,Jita,High-Sec,stable,stable,Sarkal Anthar,2023-04-27 18:45:08,86400,2023-04-28 18:45:08,Sarkal Anthar,2023-04-27 19:26:50`,
];

function createTestInput() {
  const output = [
    ...rawInput,
    ...unTypedWormhole,
    ...unamedSig,
    ...wrongLeadsTo,
  ];

  return output.join("\n");
}

export const errorTestInput = createTestInput();
