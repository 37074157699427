import { hubs, wormHolePattern } from "../services/constants";
import {
  getConstellationInfo,
  getSecureRouteJumps,
  getSystemInfo,
  getSystemsIds,
  getWormholeClass,
  SystemIDMap,
} from "./esiService";

import _ from "lodash";
import { Metrics, Output, TradeRoutes } from "../interfaces/output";
import { SigData, Wormhole } from "../interfaces/signature";
import { TripWireSite, TripWireWormhole } from "../interfaces/tripwire";
import { Signature } from "../models/signature";
import { loc, special, whList } from "../wormholes/wormholeData";
import { wormHoleTypes } from "../wormholes/wormHoleTypes";

const systems = [
  { name: "Jita", id: 30000142 },
  { name: "Amarr", id: 30002187 },
  { name: "Dodixie", id: 30002659 },
  { name: "Hek", id: 30002053 },
  { name: "Rens", id: 30002510 },
];

const systemIdFromArray = (systemsArray: SystemIDMap[], systemName: string) => {
  const system = systemsArray.find((system) => system.name === systemName);
  if (system) return system.id;
};

export const eveSurvivalLink = (name: string) => {
  const baseURl = "https://eve-survival.org/wikka.php?wakka=";
  const siteName = name.replace(/\s/g, "");
  return baseURl + siteName;
};

// const signatures = (data) => [
//   { visual: "Ore", data: data.ore, colour: colors.Cyan },
//   { visual: "Gas", data: data.ore, colour: colors.Cyan },
//   { visual: "Relic", data: data.ore, colour: colors.Cyan },
//   { visual: "Data", data: data.ore, colour: colors.Cyan },
//   { visual: "Non Static Wormholes", data: data.ore, colour: colors.Cyan },
// ];

const addWormHoleInfo = (
  sig: TripWireWormhole | TripWireSite,
  signature: Signature
) => {
  if ("wormholeType" in sig) {
    // TripWire says it's a K162, but the copy paste data says ????
    const correctType = sig.wormholeType === "????" ? "K162" : sig.wormholeType;

    const wormHole: Wormhole = {
      location: sig.location,
      wormholeType: correctType,
      life: sig.life,
      mass: sig.mass,
      destination: {
        systemName: sig.destination,
        systemID: NaN,
        secStatus: null,
        wormholeClass: null,
        systemInfo: null,
        constellationInfo: null,
        tradeHubs: {
          Jita: null,
          Amarr: null,
          Dodixie: null,
          Hek: null,
          Rens: null,
        },
      },
    };

    signature.wormHole = wormHole;
  }
};

// const addCombatInfo = (
//   sig: TripWireWormhole | TripWireSite,
//   signature: Signature
// ) => {
//   if (!("wormholeType" in sig)) signature.siteURL = eveSurvivalLink(sig.name);
// };

const generateArrayOfSignatures = async (
  tripWireArray: (TripWireWormhole | TripWireSite)[]
) => {
  const arrayOfSignatures: Signature[] = [];

  for (const sig of tripWireArray) {
    const name = "wormholeType" in sig ? sig.destination : sig.name;

    const sigData: SigData = {
      id: sig.id,
      type: sig.type,
      name,
      createdByUser: sig.createdByUser,
      createdTime: sig.createdTime,
      lastModifiedByUser: sig.lastModifiedByUser,
      lastModifiedTime: sig.lastModifiedTime,
      dateOfDeath: sig.dateOfDeath,
      lifeTimeInSeconds: sig.lifeTimeInSeconds,
    };

    const signature = new Signature(sigData);

    // Add Wormhole Data if needed
    addWormHoleInfo(sig, signature);

    // Add SiteUrl if needed
    // addCombatInfo(sig, signature);

    arrayOfSignatures.push(signature);
  }

  // If Wormhole get more data from ESI
  const data = await populateWormholeDataFromESI(arrayOfSignatures);

  return data;
};

const populateWormholeDataFromESI = async (sigs: Signature[]) => {
  let newSigs: Signature[];

  // SystemIDs
  newSigs = await addWormholeDestinationSystemIds(sigs);

  // System Info
  newSigs = await addWormholeDestinationSystemInfo(newSigs);

  // Add WormholeClass
  newSigs = await addWormHoleDestinationClass(newSigs);

  // Trade Connections
  newSigs = await addWormHoleDestinationTradeRoutes(newSigs);

  return newSigs;
};

const addWormholeDestinationSystemIds = async (sigs: Signature[]) => {
  const systemsToGet = [];

  for (const sig of sigs) {
    if (sig.wormHole) {
      let { systemName } = sig.wormHole.destination;
      systemsToGet.push(systemName);
    }
  }

  // Remove duplicates, rare, but can have 2 connections to the same system!

  const uniqueSystemsToGet = [...new Set(systemsToGet)];

  const results = await getSystemsIds(uniqueSystemsToGet);
  const systemIDs: number[] = [];

  // Update all sigs that are wormholes with their systemID
  for (const sig of sigs) {
    if (sig.wormHole) {
      let { destination } = sig.wormHole;
      const id = systemIdFromArray(results, destination.systemName);
      destination.systemID = id;
      systemIDs.push(id);
    }
  }

  return sigs;
};

const addWormholeDestinationSystemInfo = async (sigs: Signature[]) => {
  // These wormholes need their systemID

  for (const sig of sigs) {
    if (sig.wormHole) {
      const { destination } = sig.wormHole;

      destination.systemInfo = await getSystemInfo(destination.systemID);
      destination.secStatus = securityStatus(
        destination.systemInfo.security_status
      );
    }
  }

  return sigs;
};

const addWormHoleDestinationClass = async (sigs: Signature[]) => {
  for (const sig of sigs) {
    if (sig.wormHole) {
      const { systemName } = sig.wormHole.destination;

      if (wormHolePattern.test(systemName)) {
        sig.wormHole.destination.constellationInfo = await getConstellationInfo(
          sig.wormHole.destination.systemInfo.constellation_id
        );

        const { constellationInfo } = sig.wormHole.destination;

        const oldClass = getWormholeClass(constellationInfo);

        let result = oldClass;

        const wormholeObj = codeToWormhole(sig.wormHole.wormholeType);

        if (wormholeObj?.special) {
          if (wormholeObj.dest === loc.C13) {
            result = loc.C13;
          } else if (wormholeObj.special === special.DRIFTER) {
            result = wormholeObj.dest;
          }
        }

        sig.wormHole.destination.wormholeClass = result;

        sig.wormHole.destination.secStatus = "WH";
      }
    }
  }
  return sigs;
};

const codeToWormhole = (code: string) => {
  const results = whList.filter((wh) => wh.code == code);

  return results[0];
};

const addWormHoleDestinationTradeRoutes = async (sigs: Signature[]) => {
  for (const sig of sigs) {
    if (sig.wormHole) {
      const { systemName, systemID, tradeHubs } = sig.wormHole.destination;

      const jSpace = wormHolePattern.test(systemName);
      if (!jSpace) {
        for (const system of systems) {
          const jumps = await getSecureRouteJumps(systemID, system.id);
          tradeHubs[system.name] = jumps;
        }
      }
    }
  }
  return sigs;
};

export const generateMetrics = (sigs: Signature[]): Metrics => {
  // LastUpdatedObject
  const lastUpdatedObject = lastModifiedSig(sigs);

  // LastUpdatedTime
  const lastModified = lastUpdatedObject.sigData.lastModifiedTime;

  // LastUpdatedUser
  const lastUser = lastUpdatedObject.sigData.lastModifiedByUser;

  // Buckets
  const buckets = sortSitesIntoBuckets(sigs);

  // console.log("buckets", buckets);

  // Trade Routes
  const tradeRoutes = generateTradeRoutes(sigs);

  return { lastModified, lastUser, tradeRoutes, ...buckets };
};

const lastModifiedSig = (sigs: Signature[]) => {
  return _.orderBy(sigs, (sig) => sig.sigData.lastModifiedTime, ["desc"])[0];
};

const sortSitesIntoBuckets = (sigs: Signature[]) => {
  const output: Output = {};
  const wormholes = [
    "static",
    "wandering",
    "static_wanderer",
    "exit",
    "frigate",
    "drifter",
  ];

  for (const sig of sigs) {
    const type = sig.sigData.type;

    if (type !== "wormhole") {
      // Check to see if an array is there before we push, else make one
      if (!output[type]) output[type] = [];
      output[type].push(sig);
    } else {
      for (const type of wormholes)
        if (wormHoleTypes(type).includes(sig.wormHole.wormholeType)) {
          if (!output[type]) output[type] = [];
          output[type].push(sig);
        }
    }
  }

  return output;
};

const trueToSecStatus = function (num: number) {
  const factor = 10 ** 1;
  return Math.round(num * factor) / factor;
};

const securityStatus = (trueSecStatus: number) => {
  const secStatus = trueToSecStatus(trueSecStatus);

  if (secStatus >= 0.5) return "HS";
  if (secStatus >= 0.1) return "LS";
  if (secStatus > -1) return "NS";
  return "WH/Poch";
};

const generateTradeRoutes = (sigs: Signature[]): TradeRoutes => {
  // This just focuses on generating an object

  const tradeRoutes: TradeRoutes = {
    Jita: undefined,
    Amarr: undefined,
    Dodixie: undefined,
    Hek: undefined,
    Rens: undefined,
  };

  for (const sig of sigs) {
    // If the signature is not a wormhole, continue
    if (!sig.wormHole) {
      continue;
    }

    // If the destination system is a JXXXXXX then don't bother either
    if (wormHolePattern.test(sig.wormHole.destination.systemName)) {
      continue;
    }

    // Now we should only have signatures with trade hub connections!
    for (const system in hubs) {
      // If we haven't got anything to compare against, then just use this sig
      if (!tradeRoutes[system]) {
        tradeRoutes[system] = sig;
        continue;
      }

      const sigJumps: number = sig.wormHole.destination.tradeHubs[system];
      const currentClosest: number =
        tradeRoutes[system].wormHole.destination.tradeHubs[system];

      if (sigJumps < currentClosest) {
        tradeRoutes[system] = sig;
      }
    }
  }

  return tradeRoutes;
};

export { generateTradeRoutes, generateArrayOfSignatures, systemIdFromArray };
