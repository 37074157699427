import { TripWireSite, TripWireWormhole } from "../interfaces/tripwire";

export function checkInput(
  sigs: (TripWireSite | TripWireWormhole)[]
): string[] {
  const errors = [];

  const nope = ["Null-Sec", "Low-Sec", "High-Sec"];

  for (const sig of sigs) {
    if (sig.type === "unknown") {
      errors.push(
        `Signature: ${sig.id} cannot be type 'unknown', select the correct type in TripWire.`
      );
    }

    if ("wormholeType" in sig) {
      // sig.wormholeType === '????' = K162?
      if (sig.wormholeType === "null") {
        errors.push(
          `Wormhole: ${sig.id} has no type, enter a Type Code in TripWire.`
        );
      }

      if (nope.includes(sig.destination)) {
        errors.push(
          `Wormhole: ${sig.id} cannot lead to ${sig.destination}, this needs to be an actual system.`
        );
      }

      if (sig.destination === "") {
        errors.push(`Wormhole: ${sig.id} needs to lead to an actual system.`);
      }
    } else {
      if (sig.name === "") {
        errors.push(
          `Signature: ${sig.id} has no name, copy/paste the sig into TripWire from EVE Online. `
        );
      }
    }
  }

  return errors;
}
